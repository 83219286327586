header {
    border-top: 6px solid #888e99;
    // background-color: var(--bs-cream);
    background: url(./assets/img/menu-bg.png);
    height: 100%;
    .layerOne {
        padding: 35px 0 14px;
    }
    .logo {
        img {
            height: 67.5px;
        }
    }

    .phoneBlock {
        position: relative;
        display: inline-flex;
        margin: 0 27px;
        .flatIcon {
            max-width: 100%;
            height: auto;
            display: block;
            margin-right: 20px;
            &:before {
                font-size: 35px;
            }
        }
        .as {
            a {
                color: #333333;
                font-size: 22px;
                line-height: 24px;
                font-family: "Lato";
                font-weight: 900;
                color: #333333;
                &:hover {
                    color: #a175aa;
                }
            }
        }
        p {
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 0px;
            text-align: left;
            font-family: Montserrat;
            color: #1f1f1f;
            font-size: 17px;
            padding-bottom: 2px;
        }
    }

    .main-nav {
        border-top: 1px solid #c9cdd0;
        background-color: rgba(229, 223, 223, 0.7);
        padding: 17px 0;
        nav {
            margin: 0;
            ul {
                margin: 0 -25px;
                li {
                    a {
                        font-size: 15px;
                        line-height: 18px;
                        font-weight: 800;
                        text-transform: uppercase;
                        padding: 5px 25px;
                        letter-spacing: 1px;
                        &:hover,
                        &.active {
                            color: var(--bs-red);
                        }
                    }
                }
            }
        }
    }
}

.heroSection {
    position: relative;
    text-align: center;
    height: 60vh;
    overflow: hidden;
    background-size: 100% auto;
    background-position: center 21%;
    // padding: 365px 0px 100px;

    img {
        width: 100%;
    }
    .cont {
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translate(-50%, 0%);
        height: 196px;
        overflow: hidden;
        &.expand {
            h1 {
                opacity: 1;
                transform: translateY(0);
            }
            .btn-Wrap {
                transform: translateY(0);
                opacity: 1;
            }
        }
        h1 {
            text-shadow: 1px 1px 6px #6f6d6d;
            color: #fff;
            text-align: center;
            line-height: 50px;
            letter-spacing: 0px;
            font-weight: 800;
            font-size: 2.5vw;
            margin-bottom: 3rem;
            transform: translateY(-50%);
            opacity: 0;
            transition: ease 1s;
        }
        .btn-Wrap {
            transform: translateY(50%);
            opacity: 0;
            transition: ease 1s;
        }
    }
}

.homeLayer2 {
    background-color: #04273c;
    padding: 46px 0;
    .mainCont {
        width: 720px;
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    p {
        line-height: 1.5em;
        color: #fff;
        font-family: Montserrat;
        font-size: 14px;
        strong:first-child {
            font-size: 24px;
            font-family: "Raleway";
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            display: inline-block;
            width: 100%;
        }
    }
}

.homeLayer3 {
    p.prof-bio {
        margin-top: 75px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 43px;
        width: 1170px;
        max-width: 100%;
        font-size: 17px;
        font-family: "Montserrat", sans-serif;
        line-height: 1.65;
        &:after {
            content: "";
            clear: both;
        }
        strong:first-child {
            font-size: 1.8em;
            font-family: "Raleway";
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            display: inline-block;
            width: 100%;
            line-height: 1.2em;
        }
        img {
            float: left;
            margin-right: 2em;
            margin-bottom: 1em;
        }
    }
}

.homeLayer4 {
    background-color: var(--bs-cream);
    padding-bottom: 3.4rem;
    h2 {
        padding-top: 42px;
        padding-bottom: 30px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        font-size: 34.3px;
        line-height: 36px;
    }
    @media (min-width: 1200px) {
        .row {
            margin: 0 -100px;
            > div {
                padding: 0 25px;
            }
        }
    }

    .serviceCard {
        position: relative;
        display: block;
        img {
            width: 100%;
            height: 430px;
            object-fit: cover;
        }

        .cont {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: calc(100% - 95px);
            width: 75%;
            z-index: 99;
            padding: 30px 29px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.red {
                background-color: rgba(#b62837, 0.6);
            }
            &.blue {
                background-color: rgba(#042f48, 0.6);
            }

            p {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                font-size: 25px;
                font-family: "Raleway";
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1.4em;
                color: #fff;
                margin: 0;
                em:first-of-type {
                    font-size: 0.7em;
                    font-style: normal;
                    margin-top: -0.4em;
                    display: block;
                }
                i {
                    font-size: 0.55em;
                    font-style: normal;
                    margin-top: -0.4em;
                    display: block;
                    line-height: 1.3em;
                    text-transform: none;
                }
                span {
                    font-size: 0.7em;
                    font-style: normal;
                    margin-top: -2em;
                    display: block;
                    border: 2px solid white;
                    border-radius: 30px;
                    width: fit-content;
                    padding-left: 1.2em;
                    padding-right: 1.2em;
                    margin-left: auto;
                    margin-right: auto;
                    text-transform: none;
                    line-height: inherit;
                    padding-top: 3px;
                    padding-bottom: 4px;
                }
            }
        }
    }
}

.homeLayer5 {
    .contact-info-left {
        width: 30%;
        background-color: var(--bs-blue);
         display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: white;
        align-items: center;
        font-family: Montserrat;
        .inner {
            width: 325px;
            max-width: 100%;
            margin: auto;
            height: 400px;
        }
        div {
            line-height: 1.5em;
            text-align: left;
            width: 100%;
        }
        hr {
            background-color: #fff;
            width: 90%;
            opacity: 1;
            margin: 0rem 0;
        }
        .fa {
            margin-right: 6px;
        }
        a {
            width: auto;
            font-family: Montserrat;
        }
    }

    .contact-form-right {
        width: 40%;
        text-align: center;
        padding-left: 2.5em;
        padding-right: 2.5em;
        background-color: var(--brand-cream);
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top: 1px solid white;
        background-color: var(--bs-cream);
        h3 {
            text-align: center;
            margin-bottom: 1em;
            font-weight: 700;
            text-transform: uppercase;
            .red-letters {
                color: var(--bs-red);
            }
        }
        p {
            color: #1f1f1f;
            font-size: 17px;
        }
        a {
        }
    }
    .photo-right-contact {
        width: 30%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.contactFormWrap {
    padding: 4rem 0;
    h2 {
        padding: 20px 0;
        font-weight: 700;
        font-size: 34px;
        line-height: 36px;
        text-transform: uppercase;
        text-align: center;
    }
    .form {
        width: 900px;
        max-width: 100%;
        .form-group {
            margin-bottom: 1rem;
        }
        input {
            padding: 0 15px;
            height: 40px;
            border: 1px solid #dcdcdc;
            border-radius: 0;
            box-shadow: none !important;
            font-family: "Open Sans";
            color: #cecece;
        }
        textarea {
            padding: 15px;
            height: 280px;
            border: 1px solid #dcdcdc;
            border-radius: 0;
            width: 100%;
            box-shadow: none !important;
            font-family: "Open Sans";
            color: #cecece;
        }
    }
}

.pageLast {
    padding: 33px 0;
    background: #1c1c1c;
    p {
        color: #ffffff;
        font-size: 14px;
        margin: 0;
        font-family: Montserrat;
    }
}

.innerBanner {
    position: relative;
    background-position: 100% 40% !important;
    background-size: 138% !important;
    padding: 100px 0;
    min-height: 300px;
    display: flex;
    align-items: center;
    &.bigInner {
        height: 460px;
        background-position: 50% 13% !important;
        background-size: 100% !important;
        position: relative;
        &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.3);
            content: "";
            display: block !important;
            z-index: 1;
        }
    }
    .content {
        color: #fff;
        font-family: Raleway;
        font-weight: 700;
        line-height: 57px;
        font-size: 48px;
        text-transform: uppercase;
        width: 100%;
        position: relative;
        z-index: 2;
        h1 {
            margin-bottom: 5px;
            font-size: inherit;
            font-weight: inherit;
        }
        h2 {
            font-size: 0.62em;
            font-weight: inherit;
        }
    }
}

.e-m-w-f-y-o-v-Wrap {
    padding: 50px 0;
    p {
        line-height: 26px;
        font-family: Montserrat;
        color: #1f1f1f;
        font-size: 17px;
        padding: 0 5px;
        img {
            width: 450px;
            height: 300px;
            &:first-of-type {
                float: left;
                margin-right: 1em;
                margin-bottom: 0.5em;
            }
            &:nth-of-type(2) {
                float: right;
                margin-left: 1em;
                margin-bottom: 1em;
            }
        }
    }
}

.body-image-services {
    padding: 50px 0;
    p {
        line-height: 26px;
        font-family: Montserrat;
        color: #1f1f1f;
        font-size: 17px;
        padding: 0 5px;

        img:first-of-type {
            float: right;
            margin-left: 1em;
            margin-bottom: 1em;
        }
    }
}

.contactInfoWrap {
    padding-top: 55px;
    @media (min-width: 1200px) {
        .row {
            max-width: 1060px;
            margin: 0 auto;
        }
    }
    @media (min-width: 1300px) {
        .row {
            flex-wrap: nowrap;
        }
    }
    @media (max-width: 1300px) {
        .service-panel {
            width: 300px;
        }
    }
    @media (min-width: 1300px) {
        .service-panel {
            width: 405px;
        }
    }
    .service-panel {
        border: 1px solid #e6e6e6;
        img {
            width: 100%;
            height: auto;
        }
        .info-service {
            padding: 1em;
            h6 {
                font-size: 18px;
                line-height: 22px;
                text-transform: none;
                margin-bottom: 10px;
                margin-top: 3px;
                color: #242424;
                font-family: "Raleway";
                font-weight: 900;
                letter-spacing: 0.3px;
            }
            p {
                font-family: Montserrat;
                color: #1f1f1f;
                font-size: 17px;
                min-height: 5em;
                line-height: 26px;
            }
            hr {
                margin-top: 20px;
                margin-bottom: 20px;
                background-color: #eee;
                opacity: 1;
            }
            .service-cta-info {
                font-family: Montserrat;
            }
            a {
                padding: 12px 35px !important;
                font-size: 16px !important;
                line-height: 24px !important;
                border-radius: 30px;
                background: #b62837;
                font-family: "Open Sans";
                color: #fff;
            }
        }
    }
}

@media (max-width: 1199px) {
    header .main-nav nav {
        ul {
            margin: 0 -16px;
            li {
                a {
                    padding: 5px 16px;
                }
            }
        }
    }

    .heroSection {
        height: 480px;
        .cont {
            width: 75%;
        }
    }

    .homeLayer5 {
        .contact-info-left {
            .inner {
                width: calc(100% - 30px);
                font-size: 75%;
            }
        }
    }
}
@media (max-width: 991px) {
    .heroSection {
        height: 400px;
        img {
            margin: 0;
        }
        .cont {
            width: 75%;
            h1 {
                font-size: 40px;
            }
        }
    }
    .homeLayer3 {
        img {
            width: 300px !important;
            height: auto;
        }
    }

    .homeLayer5 {
        > div {
            flex-wrap: wrap;
        }
        .contact-info-left {
            width: 50%;
            .inner {
                 font-size: 75%;
                height: 330px;
            }
        }
        .contact-form-right {
            width: 50%;
        }
        .photo-right-contact {
            width: 100%;
        }
    }

    .e-m-w-f-y-o-v-Wrap {
        p {
            img {
                width: 330px;
                height: 250px;
            }
        }
    }
    .body-image-services {
        p {
            img {
                width: 330px;
                height: 250px;
            }
        }
    }
    .innerBanner {
        padding: 50px 0;
        min-height: 230px;
        &.bigInner {
            height: 52vw;
        }
        .content {
            font-size: 36px;
        }
    }

    .pageLast {
        padding: 20px 0;
        p {
            text-align: center;
        }
    }
    .contactInfoWrap {
        .service-panel {
            width: 100%;
        }
        .col-md-6 {
            padding-bottom: 30px;
        }
    }
}

@media (max-width: 767px) {
    header {
        background-image: none;
        border: 0;
        .logo {
            display: inline-block;
            padding: 16px 0;
            img {
                height: 40.5px;
            }
        }
        .mobileNavBtn {
            .menuIcon {
                height: 17px;
                width: 25px;
                cursor: pointer;
                position: relative;
                &.menuIconActive {
                    span:nth-child(1) {
                        -webkit-transform: translate(70px);
                        transform: translate(70px);
                        opacity: 0;
                        visibility: hidden;
                    }
                    span:nth-child(4) {
                        -webit-transform: translate(-70px);
                        transform: translate(-70px);
                        opacity: 0;
                        visibility: hidden;
                    }
                    span:nth-child(2) {
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        background: #b07bb6;
                    }
                    span:nth-child(3) {
                        -webkit-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                        background: #b07bb6;
                    }
                }

                span {
                    height: 3px;
                    width: 25px;
                    background: #202020;
                    display: block;
                    border-radius: 5px;
                    -webkit-transition: all 200ms linear;
                    transition: all 200ms linear;
                    position: absolute;
                    &:nth-child(1) {
                        top: 0;
                        left: 0;
                    }
                    &:nth-child(2) {
                        top: 43%;
                        left: 0;
                    }
                    &:nth-child(3) {
                        top: 43%;
                        left: 0;
                    }
                    &:nth-child(4) {
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
        .layerOne {
            padding: 0;
            .row {
                > div {
                    &:nth-child(1) {
                        order: 2;
                    }
                    &:nth-child(2) {
                        order: 1;
                        background: #f2f2f2;
                        padding: 20px 0;
                        width: 100%;
                        text-align: center;
                    }
                    &:nth-child(3) {
                        order: 3;
                    }
                }
            }
            .phoneBlock {
                .flatIcon:before {
                    margin-left: 0;
                }
                p {
                    font-size: 14px;
                }
                h5 {
                    margin: 0;
                }
                .as a {
                    font-size: 16px;
                    line-height: normal;
                }
            }
        }
        .main-nav {
            position: fixed;
            left: -250px;
            top: 0;
            z-index: 99;
            background-color: var(--bs-cream);
            height: 100vh;
            width: 250px;
            transition: ease 0.3s;
            &.active {
                left: 0;
            }

            .container {
                padding: 0;
            }
            nav {
                ul {
                    margin: 0;
                    li {
                        padding: 0;
                        a {
                            padding: 10px 15px;
                            line-height: normal;
                            display: block;
                            font-size: 13px;
                            border-bottom: 1px solid #e1e1e1;
                            &.active {
                                color: #fff;
                                background-color: var(--bs-red);
                            }
                        }
                    }
                }
            }
        }
    }

    .innerBanner {
        &.bigInner {
            height: 52vw;
            min-height: auto;
        }
        .content {
            font-size: 30px;
        }
    }

    .heroSection {
        height: 55vw;
        .cont {
            h1 {
                font-size: 22px;
                margin-bottom: 2rem;
                line-height: normal;
            }
        }
    }

    .homeLayer5 {
        > div {
            flex-wrap: wrap;
        }
        .contact-info-left {
            width: 100%;
        }
        .contact-form-right {
            width: 100%;
            padding: 3rem 1rem;
        }
    }
}

div#btt {
    background: none repeat scroll #222222;
    opacity: 0.8;
    border: 1px solid #333333;
    border-radius: 2px;
    bottom: 21px;
    cursor: pointer;
    padding: 8px 15px;
    position: fixed;
    right: 20px;
    z-index: 99;
    color: #777777;
}

@media (max-width: 575px) {
    .homeLayer3 p.prof-bio img {
        float: none;
        margin: 0 0 20px;
        display: block;
        width: 100% !important;
    }
    .innerBanner {
        padding: 0;
        min-height: 100px;
        .content {
            font-size: 14px;
        }
    }

    .heroSection {
        .cont {
            height: 90px;
             h1 {
                font-size: 15px;
                margin-bottom: 1rem;
            }
            .btn {
                padding: 7px 30px !important;
                font-size: 13px !important;
                line-height: 20px !important;
            }
        }
    }

    .homeLayer4 {
        .serviceCard {
            img {
                height: 340px;
            }
            .cont {
                padding: 20px;
                height: 90%;
                width: 90%;
                p {
                    font-size: 20px;
                }
            }
        }
    }

    .e-m-w-f-y-o-v-Wrap p {
        font-size: 14px;
        img:first-of-type {
            float: none;
            margin: 0 0 2rem !important;
            width: 100% !important;
            height: auto !important;
        }
        img:nth-of-type(2) {
            float: none;
            margin: 0 0 2rem !important;
            width: 100% !important;
            height: auto !important;
        }
    }
    .body-image-services {
        p {
            font-size: 14px;
            img:first-of-type {
                float: none;
                margin: 0 0 2rem !important;
                width: 100% !important;
                height: auto !important;
            }
        }
    }
}
