$theme-colors: (
    "blue": #042f48,
    "red": #b62837,
    "cream": #fff6da,
    "primary": #b62837,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px,
);
//font-family: 'Raleway', sans-serif;
$font-family-sans-serif: "Raleway", sans-serif;
// @import "@fancyapps/ui/dist/fancybox.css";
@import "~bootstrap/scss/bootstrap.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import "./assets/css/flaticon.css";
@import "./assets/css/font-awesome.min.css";

:root {
    --bs-blue: #042f48;
    --bs-cream: #fff6da;
    --bs-red: #b62837;
}

body {
    font-family: "Raleway", sans-serif;
}

// ::-webkit-scrollbar {
//     width: 12px;
//     height: 12px;
//     border-radius: 4px;
// }

// ::-webkit-scrollbar-thumb {
//     background-color: var(--bs-primary);
//     border-radius: 4px;
// }

// ::-webkit-scrollbar-track {
//     background-color: #f1f1f1;
//     border-radius: 4px;
// }
button {
    box-shadow: none !important;
}
@media (min-width: 1200px) {
    .container {
        width: 1200px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 970px;
        padding: 00;
    }
}

.btn-md {
    padding: 12px 35px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    border-radius: 30px;
}
.btn-primary {
    position: relative;
    overflow: hidden;
    border: 0;
    font-family: "Open Sans";
    span {
        position: relative;
        z-index: 2;
    }
    &:hover {
        &::before {
            -webkit-transform: scale(2);
            transform: scale(2);
        }
    }
    &::before {
        z-index: 1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #68a4ec;
        border-radius: 100%;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        background: #042f48;
    }
}
.ff-lora {
    font-family: "Lora", serif;
}
.ff-open {
    font-family: "Open Sans", sans-serif;
}
.ff-work {
    font-family: "Work Sans", sans-serif;
}

.mat-select-black {
    .MuiSelect-select:focus {
        background-color: transparent !important;
        // font-family: Helvetica, Arial, sans-serif;
    }
    .MuiSelect-icon {
        color: #000000 !important;
    }
}
.mat-select-dark2 {
    .MuiSelect-select:focus {
        background-color: transparent !important;
    }
    .MuiSelect-icon {
        color: #94a2b3 !important;
    }
}

section {
    animation: pageAni 0.3s;
}

input[type="text"],
input[type="password"],
input[type="date"] {
    /* Black */
    border: 0.75px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0.5rem;
    @media (min-width: 576px) {
        padding: 1rem;
    }
    font-size: 1rem;
    font-family: var(--bs-lora);
    box-shadow: none !important;
    &:focus {
        border-color: #222;
    }
}

@keyframes pageAni {
    0% {
        opacity: 0;
        transform: scale(0.96);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@media (min-width: 1440px) {
    .container-xl {
        max-width: 1350px;
    }
}
svg {
    user-select: none;
}
img {
    user-select: none;
}
* {
    outline: none !important;
}
a {
    transition: ease 0.3s;
    text-decoration: none !important;
    cursor: pointer;
    color: inherit;
}
button {
    transition: ease 0.3s;
    -webkit-appearance: none;
}


@for $fw from 0 to 10 {
    .fw-#{$fw} {
        font-weight: $fw * 100 !important;
    }
}
@for $fade from 0 to 10 {
    .fade-#{$fade} {
        opacity: $fade / 10 !important;
    }
}

// ************ Font size  *************
$fontSizeLastValue: 50;
@for $fontSize from 0 through $fontSizeLastValue {
    .fs-#{$fontSize} {
        font-size: #{$fontSize / 16}rem !important;
    }
}
@each $deviceName, $screenWidth in $grid-breakpoints {
    @media (min-width: $screenWidth) {
        @for $fontSize from 0 through $fontSizeLastValue {
            .fs-#{$deviceName}-#{$fontSize} {
                font-size: #{$fontSize / 16}rem !important;
            }
        }
    }
}
