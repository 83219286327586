/*
  	Flaticon icon font: Flaticon
  	Creation date: 06/02/2017 16:58
  	*/

@font-face {
  font-family: "Flaticon";
  /* src: url("../fonts/Flaticon.eot"); */
  src: url("../fonts/Flaticon.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
 

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 20px;
}

.flaticon-gestures:before {
  content: "\f100";
}
.flaticon-interface:before {
  content: "\f101";
}
.flaticon-social:before {
  content: "\f102";
}
.flaticon-technology:before {
  content: "\f103";
}
.flaticon-technology-1:before {
  content: "\f104";
} 